import React from "react";
import StripeCheckout from "react-stripe-checkout";

const StripeCheckoutButton = ({price}) => {
    const priceForStripe = price * 100;
    const publishableKey = "pk_test_51JG2L7Klf58Uw41lensNkPbOy8NjRAddAiAoXyovlErL6HTOinAqF17ShSajJ5aopJUIM7qPaeHYARcGN2G0fDSC00qL5L027R";

    const onToken = token => {
        console.log(token);
        alert("Payment Successful");
    };

    return (
      <StripeCheckout
          label="Pay Now"
          name="Crwn CLothing Ltd."
          billingAddress
          shippingAddress
          image="https://svgshare.com/i/CUz.svg"
          description={`Your total is $${price}`}
          amount={priceForStripe}
          panelLabel="Pay Now"
          token={onToken}
          stripeKey={publishableKey}
      />
    );
};

export default StripeCheckoutButton;