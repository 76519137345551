import firebase from "firebase";
import "firebase/firestore"
import "firebase/auth"

const config = {
    apiKey: "AIzaSyDKlLOUf7Cn3DbAbCTByoEGHak8yBHV1PM",
    authDomain: "crwn-db-a2221.firebaseapp.com",
    projectId: "crwn-db-a2221",
    storageBucket: "crwn-db-a2221.appspot.com",
    messagingSenderId: "249555528092",
    appId: "1:249555528092:web:afe842de55aa601f658059",
    measurementId: "G-VSYTNDYXPZ"
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();
    console.log("User snapshot: ", snapShot);
    if (snapShot.exists) {
        return userRef;
    }

    const {displayName, email} = userAuth;
    const createdAt = new Date();
    try {
        await userRef.set({
            displayName,
            email,
            createdAt,
            ...additionalData
        })
    } catch (error) {
        console.log("error while creating user: ", error.message);
    }
    return userRef;
}

firebase.initializeApp(config);

export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
    const collectionRef = firestore.collection(collectionKey);
    console.log(collectionRef);

    const batch = firestore.batch();
    objectsToAdd.forEach(obj => {
        const docRef = collectionRef.doc();
        batch.set(docRef, obj);
    });

    return await batch.commit();
}

export const convertCollectionsSnapshotToMap = (collections) => {
    const transformCollection = collections.docs.map(doc => {
        const {title, items} = doc.data();
        return {
            routeName: encodeURI(title.toLowerCase()),
            id: doc.id,
            title,
            items
        }
    })

    return transformCollection.reduce((acc, collection) => {
        acc[collection.title.toLowerCase()] = collection;
        return acc;
    }, {});
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: "select_account"})
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
